import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Accordion, Card, Button, Nav, Navbar } from 'react-bootstrap';
import { GetPlayerContractInfo, GetPlayerContractPdf, GetPlayerContractPdfList, GetTransactions } from '../../Services/ApiSvc';
import { customDateString, toCurrency } from '../Common/CommonFunctions';
import TabulatorTable from '../Common/TabulatorTable';
import PDFExporter from '../Common/PDFExporter';
import ExcelExporter from '../Common/ExcelExporter';

function Contracts(props) {
    const [availableContractPdfs, setAvailableContractPdfs] = useState(null);
    const [columnGroups, setColumnGroups] = useState(null);
    const [contracts, setContracts] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.groups.length < 1) {
            return;
        }

        let colGroups = {};

        colGroups.transaction = props.groups.find(group => group.id === 23).columns.slice();
        colGroups.salaryInfo = props.groups.find(group => group.id === 61).columns.slice();
        colGroups.miscSalaryInfo = props.groups.find(group => group.id === 62).columns.slice();
        colGroups.bonusSalaryInfo = props.groups.find(group => group.id === 63).columns.slice();
        colGroups.optionInfo = props.groups.find(group => group.id === 64).columns.slice();

        (async () => {
            setLoading(true);
            let contractPdfs = await GetPlayerContractPdfList();
            let contractFileNameRegex = new RegExp('(?<year>.*)( Contracts/)(?<contractId>.*)(\.pdf)')
            let PdfByContractId = contractPdfs.data.reduce((acc, cur) => {
                let contractRegexMatch = contractFileNameRegex.exec(cur);
                if (contractRegexMatch) {
                    acc[contractRegexMatch.groups.contractId] = cur;
                }
                return acc;
            }, {})

            setAvailableContractPdfs(PdfByContractId);
            setLoading(false);
        })()

        setColumnGroups(colGroups);
    }, [])

    useEffect(() => {
        if (props.playerId < 1)
            return;

        const transactionsTask = GetTransactions(props.playerId);
        const contractsTask = GetPlayerContractInfo(props.playerId);

        Promise.all([contractsTask, transactionsTask])
            .then((values) => {
                let contracts = values[0].sort((a, b) => new Date(b.SigningDate) - new Date(a.SigningDate));
                let transactions = values[1];

                setContracts(contracts);
                setSelectedContract(contracts[0]);
                setTransactions(transactions);
            });

    }, [props.playerId]);    

    function updateSelectedContract(contractId) {
        setSelectedContract(contracts.find(contract => contract.ContractId === contractId));
    }

    return (
        <React.Fragment>
            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                contracts && selectedContract &&
                <React.Fragment>
                    <ContractSelection
                        availableContractPdfs={availableContractPdfs}
                        contracts={contracts}
                        selectedContractId={selectedContract.ContractId}
                        updateSelectedContract={updateSelectedContract}
                    />


                    <ContractInfo
                        contract={selectedContract}
                    />
                    
                    <ContractYears
                        salaryInfoColumns={columnGroups?.salaryInfo}
                        miscSalaryInfoColumns={columnGroups?.miscSalaryInfo}
                        bonusSalaryInfoColumns={columnGroups?.bonusSalaryInfo}
                        contractYears={selectedContract.ContractYears}
                        contractOptions={selectedContract.ContractOptions}
                        optionInfoColumns={columnGroups.optionInfo}
                    />
                    {
                        transactions?.length > 0 &&
                        <div className='pdfChunk'>
                            <h3>Most Recent Transactions (through contract signing date)</h3>
                            <div
                                style={{
                                    marginLeft: '30px',
                                    width: transactions
                                        ? (columnGroups.transaction.reduce((acc, cur) => acc + ((cur.width) > 20
                                            ? (cur.width)
                                            : 20), 0)) + 4
                                        : '100%'
                                }}>
                                <TabulatorTable
                                    cols={columnGroups.transaction}
                                    data={transactions.filter(transaction => {
                                        return new Date(transaction.TransactionDate) <= new Date(selectedContract.SigningDate);
                                    })}
                                    initSort={[{ column: 'Season', dir: 'desc' }]}
                                    paginationSize={5}
                                />
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}

function ContractSelection(props) {
    let contractSelections = props.contracts.map((contract, index) => {
        let label = <React.Fragment>
            <div>
                {contract.ContractBeginYear}-{contract.ContractEndYear}
                {` (${contract.Term}`}{contract.NumberOptionYears > 0 ? ` OPT${contract.NumberOptionYears}` : ''})
            </div>
            <div>{customDateString(new Date(contract.SigningDate))}</div>
            <div>{contract.SignTeam}</div>
            <div>{contract.SigningMLS}</div>
            <div>{contract.SigningAge}</div>
            <div>{toCurrency(contract.TotalVal)}</div>
            <div>{toCurrency(contract.AAV)}</div>
            {
                props.availableContractPdfs && Object.hasOwn(props.availableContractPdfs, contract.ContractId.toString()) &&
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                        let contractPdf = await GetPlayerContractPdf(props.availableContractPdfs[contract.ContractId.toString()]);
                        const url = window.URL.createObjectURL(contractPdf);
                        window.open(url);
                    }}
                >Open</div>
            }
            {
                (!props.availableContractPdfs || !Object.hasOwn(props.availableContractPdfs, contract.ContractId.toString())) &&
                <div>N/A</div>
            }
        </React.Fragment>
        if (props.selectedContractId === contract.ContractId) {
            return <Form.Check
                name='contract'
                label={label}
                type='radio'
                key={index}
                id={`contract-selection-radio-${contract.ContractId}`}
                style={{ fontWeight: 'bold' }}
                checked
                onClick={() => {
                    props.updateSelectedContract(contract.ContractId)
                }}
            />;
        } else {
            return <Form.Check
                name='contract'
                label={label}
                type='radio'
                key={index}
                id={`contract-selection-radio-${contract.ContractId}`}
                onClick={() => {
                    props.updateSelectedContract(contract.ContractId)
                }}
            />;
        }
    })

    return (
        <div className='pdfChunk'>
            <Row>
                <h3>Contracts</h3>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    <PDFExporter fileName='Contracts' />
                </div>
            </Row>
            <br />
            <div className={'contract-selection'}>
                <div>
                    <div className={'contract-selection-header'}>Term</div>
                    <div className={'contract-selection-header'}>Signing Date</div>
                    <div className={'contract-selection-header'}>Signing Team</div>
                    <div className={'contract-selection-header'}>Signing MLS</div>
                    <div className={'contract-selection-header'}>Signing Age</div>
                    <div className={'contract-selection-header'}>Total Value</div>
                    <div className={'contract-selection-header'}>AAV</div>
                    <div className={'contract-selection-header'}>Contract Text</div>
                </div>
                {contractSelections}
            </div>
            <br />
        </div>
    );
}

function ContractInfo(props) {
    const [descType, setDescType] = useState('mlbDesc');

    let contractDescriptions = []
    let mlbDescriptionText = props.contract.ContractYears
        .find(contractYear => contractYear.ContractDescription != null)?.ContractDescription
        ?? '';
    let milbDescriptionText = props.contract.ContractYears
        .find(contractYear => contractYear.MiLBDescription != null)?.MiLBDescription
        ?? '';

    if (mlbDescriptionText) {
        let mlbDesc = {
            contractLines: [],
            label: 'MLB Contract Description',
            type: 'mlbDesc',
        }
;
        mlbDescriptionText.split('<br />').forEach(contractLine => {
            mlbDesc.contractLines.push(<span>{contractLine}</span>);
            mlbDesc.contractLines.push(<br />);
        });
        contractDescriptions.push(mlbDesc);
    }
    if (milbDescriptionText) {
        let milbDesc = {
            contractLines: [],
            label: 'Minor League Contract Description',
            type: 'milbDesc',
        }

        milbDescriptionText.split('<br />').forEach(contractLine => {
            milbDesc.contractLines.push(<span>{contractLine}</span>);
            milbDesc.contractLines.push(<br />);
        });
        contractDescriptions.push(milbDesc);
    }

    return (
        <React.Fragment>
            <div className='contract-info pdfChunk'>
                {
                    props.contract.AdvanceConsents &&
                    props.contract.AdvanceConsents.map((advanceConsent, advanceConsentIndex) => {
                        return <>
                            <Row style={{ color: 'red' }}>
                                    
                                <Col sm={12} md={4}>
                                    <Row>
                                        <Col xs={12}>
                                            {advanceConsentIndex === 0 ? 'This Contract has Advanced Consent' : ''}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Row>
                                        <Col xs={6}>
                                            Consent Date:
                                        </Col>
                                        <Col xs={6}>
                                            {customDateString(new Date(advanceConsent.ConsentDate))}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Row>
                                        <Col xs={6}>
                                            Expire Date:
                                        </Col>
                                        <Col xs={6}>
                                            {customDateString(new Date(advanceConsent.ExpireDate))}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    })
                }
                {
                    props.contract.ReportDate &&
                    <Row style={{ color: 'red' }}>
                        <Col sm={12}>
                            <Row>
                                <Col xs={12}>
                                    Originally this was a Minor League Contract
                                    with a reporting date of {customDateString(new Date(props.contract.ReportDate))}.
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    props.contract.IsMinorLeagueContract && props.contract.ReportDate == null &&
                    <Row style={{ color: 'red' }}>
                        <Col sm={12}>
                            <Row>
                                <Col xs={12}>
                                    This is a Minor League Contract.
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col sm={12} md={4}>
                        <Row>
                            <Col xs={6}>
                                Reporting Date:
                            </Col>
                            <Col xs={6}>
                                {customDateString(new Date(props.contract.SigningDate))}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Signing Team:
                            </Col>
                            <Col xs={6}>
                                {props.contract.SignTeam}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                First Year of Contract:
                            </Col>
                            <Col xs={6}>
                                {props.contract.ContractBeginYear}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Last Year of Contract:
                            </Col>
                            <Col xs={6}>
                                {props.contract.ContractEndYear}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                No Trade Clause:
                            </Col>
                            <Col xs={6}>
                                {
                                    props.contract.NoTradeClause &&
                                    <img
                                        width='17'
                                        height='17'
                                        src='/images/check.png'
                                        style={{ float: 'right' }}
                                    />
                                }
                                {
                                    !props.contract.NoTradeClause &&
                                    <img
                                        width='17'
                                        height='17'
                                        src='/images/nocheck.png'
                                        style={{ float: 'right' }}
                                    />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={4}>
                        <Row>
                            <Col xs={6}>
                                Total Value:
                            </Col>
                            <Col xs={6}>
                                {toCurrency(props.contract.TotalVal)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Total Guaranteed:
                            </Col>
                            <Col xs={6}>
                                {toCurrency(props.contract.TotalGuaranteed)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Average Annual Value:
                            </Col>
                            <Col xs={6}>
                                {toCurrency(props.contract.AAV)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Average Guaranteed:
                            </Col>
                            <Col xs={6}>
                                {toCurrency(props.contract.AvgGuaranteed)}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={4}>
                        <Row>
                            <Col xs={6}>
                                Signing Code:
                            </Col>
                            <Col xs={6}>
                                {props.contract.SigningCode}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Career MLS at Signing:
                            </Col>
                            <Col xs={6}>
                                {props.contract.SigningMLS}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Age at Signing:
                            </Col>
                            <Col xs={6}>
                                {props.contract.SigningAge}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Position at Signing:
                            </Col>
                            <Col xs={6}>
                                {props.contract.PositionAtSigning}
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col xs={6}>*/}
                        {/*        Agent at Signing:*/}
                        {/*    </Col>*/}
                        {/*    <Col xs={6}>*/}
                        {/*        {props.contract.AgentAtSigning}*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Col>
                </Row>
                <br />
            </div>

            {
                contractDescriptions?.length > 0 &&
                <div className='contract-description pdfChunk' style={{ marginLeft: '30px'}}>
                    <Navbar bg='light' variant='light' className='playerNav'>
                        <Nav>
                            {
                                contractDescriptions.map(contractDesc => {
                                    return <Nav.Link
                                        className={descType === contractDesc.type ? 'nav-selected' : null}
                                        onClick={() => setDescType(contractDesc.type)}>
                                        {
                                            descType === contractDesc.type &&
                                            <span style={{ transform: 'translateX(68px) rotate(135deg)' }}></span>
                                        }
                                        {contractDesc.label}
                                    </Nav.Link>
                                })
                            }
                        </Nav>
                    </Navbar>
                    <div style={{
                        border: 'solid 1px black',
                        padding: '10px'
                    }}>
                        {contractDescriptions.find(contractDesc => contractDesc.type === descType).contractLines}
                    </div>
                    <br />
                </div>
            }
            {
                contractDescriptions?.length < 1 &&
                <div className='contract-description pdfChunk'>
                    <h4>Contract Description</h4>
                    <div style={{
                        border: 'solid 1px black',
                        marginLeft: '30px',
                        padding: '10px'
                    }}>No Contract Description Available</div>
                    <br />
                </div>
            }
        </React.Fragment>
    );
}

function ContractYears(props) {
    let salaryInfoWidth = 
        props.salaryInfoColumns.reduce(
            (acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20),
            0
        ) + 4;
    let miscSalaryInfoWidth =
        props.miscSalaryInfoColumns.reduce(
            (acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20),
            0
        ) + 4;
    let bonusSalaryWidth =
        props.bonusSalaryInfoColumns.reduce(
            (acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20),
            0
        ) + 4;
    let style = {
        marginLeft: '30px',
        width: `${Math.max(salaryInfoWidth, miscSalaryInfoWidth, bonusSalaryWidth) + 40}px`
    };

    return (
        <React.Fragment>
            <div className='pdfChunk'>
                <Row>
                    <h3>Contract Years</h3>
                    <ExcelExporter
                        columns={{
                            salaryInfo: props.salaryInfoColumns,
                            miscInfo: props.miscSalaryInfoColumns,
                            bonusInfo: props.bonusSalaryInfoColumns,
                            optionInfo: props.contractOptions?.length > 0 ? props.optionInfoColumns : [],
                        }}
                        data={{
                            salaryInfo: props.contractYears,
                            miscInfo: props.contractYears,
                            bonusInfo: props.contractYears,
                            optionInfo: props.contractOptions?.length > 0 ? props.contractOptions : [],
                        }}
                        notes={{
                            salaryInfo: null,
                            miscInfo: null,
                            bonusInfo: null,
                            optionInfo: null,
                        }}
                        reportName={'PlayerContract'}
                        sheetNames={
                            ['salaryInfo', 'miscInfo', 'bonusInfo']
                                .concat(props.contractOptions?.length > 0
                                    ? ['optionInfo']
                                    : []
                                )
                        }
                    />

                </Row>
                <Accordion style={style}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Salary Info
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div style={{
                                    width: props.contractYears
                                        ? salaryInfoWidth
                                        : '100%'
                                }}>
                                    <TabulatorTable
                                        cols={props.salaryInfoColumns}
                                        data={props.contractYears}
                                    />
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            <div className='pdfChunk'>
                <Accordion style={style}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Miscellaneous Info
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div style={{
                                    width: props.contractYears
                                        ? miscSalaryInfoWidth
                                        : '100%'
                                }}>
                                    <TabulatorTable
                                        cols={props.miscSalaryInfoColumns}
                                        data={props.contractYears}
                                    />
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            <div className='pdfChunk'>
                <Accordion style={style}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Bonuses & Potential Salary
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div style={{
                                    width: props.contractYears
                                        ? bonusSalaryWidth
                                        : '100%'
                                }}>
                                    <TabulatorTable
                                        cols={props.bonusSalaryInfoColumns}
                                        data={props.contractYears}
                                    />
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            <br />
            {
                props.contractOptions?.length > 0 &&
                <div className='pdfChunk'>
                    <h3>Contract Options</h3>
                    <div style={{
                        marginLeft: '30px',
                        width: props.contractOptions?.length > 0
                            ? (props.optionInfoColumns.reduce((acc, cur) => acc + ((cur.width) > 20
                                ? (cur.width)
                                : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={props.optionInfoColumns}
                            data={props.contractOptions}
                        />
                    </div>
                    <br />
                </div>
            }
        </React.Fragment>
    );
}

export default Contracts;