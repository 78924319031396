import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { columnManager } from '../Common/ColumnManager';
import StatFilter, { convertFilterToNote, createFilter } from '../Common/StatFilter';
import { GetXXBReport } from '../../Services/ApiSvc';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';

function XXBReport() {
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState({
        columns: null,
        exceptionMsg: null,
        loading: false,
        rows: null,
    });
    const [excelNotes, setExcelNotes] = useState([]);

    useEffect(() => {
        (async function Initialize() {
            let groupObj = {
                displayStats: [],
                filters: [],
            };

            let colGroups = await columnManager.getAllGroupsBySection('XXB');

            groupObj.displayStats.contract = colGroups.find(group => group.label === 'XXBReportContract').columns;
            groupObj.displayStats.player = colGroups.find(group => group.label === 'XXBReportPlayer').columns;
            groupObj.filters = colGroups.find(group => group.label === 'XXBReportFilters').columns;
            groupObj.xxbStat = await columnManager.getSingleColumnById(829);

            setColumnGroups(groupObj);
        })()
    }, []);

    async function getData(formOptions) {
        let reqParams = {
            displayStats: {
                contract: columnGroups.displayStats.contract,
                player: columnGroups.displayStats.player,
            },
            filters: formOptions.filters.slice(),
        }
        reqParams.filters.unshift(Object.assign({}, {
            formInputs: Object.assign({}, columnGroups.xxbStat.formInputs),
            index: -1,
            statId: 850,
            value: 1,
        }));
        setData({
            columns: null,
            exceptionMsg: null,
            loading: true,
            rows: null,
        });
        updateNotes(formOptions);
        let response = await GetXXBReport(reqParams);
        if (response.exceptionMsg) {
            setData(response);
            return;
        }
        groupData(response);
    }

    function groupData(resData) {
        let columns = [
            { title: 'Category', field: 'Category', width: 150, hozAlign: 'left', },
            { title: '# Players', field: 'Players', width: 70, },
            Object.assign({},
                columnGroups.displayStats.player.find(column => column.id === 780),
                { title: 'Avg Age (PY+1)', field: 'AvgAge', width: 70, },
            ),
            Object.assign({},
                columnGroups.displayStats.player.find(column => column.id === 780),
                { title: '# MLB Contracts', field: 'MLBContracts', width: 70, formatter: null, },
            ),
            Object.assign({},
                columnGroups.displayStats.player.find(column => column.id === 780),
                { title: '# MiLB Contracts', field: 'MiLBContracts', width: 70, formatter: null, },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 385),
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 541),
            ),
            //Object.assign({},
            //    columnGroups.displayStats.player.find(column => column.id === 780),
            //    { title: '# Unsigned', field: 'Unsigned', width: 70, formatter: null, },
            //),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 519),
                { title: 'Total Term (Exp)', field: 'TermExp', }
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 382),
                { title: 'Total YS (Exp)', field: 'YSExp', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 383),
                { title: 'Total AAV (Exp)', field: 'AAVExp', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 519),
                { title: 'Total Term (New)', field: 'TermNew', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 382),
                { title: 'Total YS (New)', field: 'YSNew', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 383),
                { title: 'Total AAV (New)', field: 'AAVNew', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 542),
                { title: 'Total Guaranteed (New)', field: 'GuaranteedNew', },
            ),
            Object.assign({},
                columnGroups.displayStats.player.find(column => column.id === 780),
                { title: 'Total aWAR', field: 'aWAR', width: 70, },
            ),
            Object.assign({},
                columnGroups.displayStats.player.find(column => column.id === 780),
                { title: 'ZiPS (PY+1)', field: 'zipsWAR', width: 70, },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 382),
                { title: 'YS/ZiPS', field: 'YSZiPS', },
            ),
            Object.assign({},
                columnGroups.displayStats.contract.find(column => column.id === 383),
                { title: 'AAV/ZiPS', field: 'AAVZiPS', },
            ),
        ];
        let posData = {
            H: { Category: 'H', _children: [] },
            RP: { Category: 'RP', _children: [] },
            SP: { Category: 'SP', _children: [] },
        };
        let signDateData = {
            OctNov: { Category: 'Oct/Nov', _children: [] },
            Dec: { Category: 'Dec', _children: [] },
            Jan: { Category: 'Jan', _children: [] },
            Feb: { Category: 'Feb', _children: [] },
            March: { Category: 'March', _children: [] },
            InSeason: { Category: 'InSeason', _children: [] },
            Unsigned: { Category: 'Unsigned', _children: [] },
        };
        let totalData = {
            Total: { Category: 'Total', _children: [] }
        }

        resData.rows.forEach(row => {
            totalData.Total = dataAccumulator(totalData.Total, row);
            totalData.Total._children.push(adjustProperties(row));
            switch (row[resData.columnMap.contract.PositionAtSigning]) {
                case 'RP':
                    posData.RP = dataAccumulator(posData.RP, row);
                    posData.RP._children.push(adjustProperties(row));
                    break;
                case 'SP':
                    posData.SP = dataAccumulator(posData.SP, row);
                    posData.SP._children.push(adjustProperties(row));
                    break;
                default:
                    posData.H = dataAccumulator(posData.H, row);
                    posData.H._children.push(adjustProperties(row));
                    break;
            }

            let signDate = new Date(row[resData.columnMap.contract.SigningDate]);
            let signMonth = null;
            if (signDate.getMonth() >= 9 && signDate.getFullYear() === row.PlatformSeason
                || signDate.getMonth() < 9 && signDate.getFullYear() === row.PlatformSeason + 1)
                signMonth = signDate.getMonth();

            switch (signMonth) {
                case 9:
                case 10:
                    signDateData.OctNov = dataAccumulator(signDateData.OctNov, row);
                    signDateData.OctNov._children.push(adjustProperties(row));
                    break;
                case 11:
                    signDateData.Dec = dataAccumulator(signDateData.Dec, row);
                    signDateData.Dec._children.push(adjustProperties(row));
                    break;
                case 0:
                    signDateData.Jan = dataAccumulator(signDateData.Jan, row);
                    signDateData.Jan._children.push(adjustProperties(row));
                    break;
                case 1:
                    signDateData.Feb = dataAccumulator(signDateData.Feb, row);
                    signDateData.Feb._children.push(adjustProperties(row));
                    break;
                case 2:
                    signDateData.March = dataAccumulator(signDateData.March, row);
                    signDateData.March._children.push(adjustProperties(row));
                    break;
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    signDateData.InSeason = dataAccumulator(signDateData.InSeason, row);
                    signDateData.InSeason._children.push(adjustProperties(row));
                    break;
                default:
                    signDateData.Unsigned = dataAccumulator(signDateData.Unsigned, row);
                    signDateData.Unsigned._children.push(adjustProperties(row));
                    break;
            }
        })

        setData({
            columns: columns,
            exceptionMsg: null,
            loading: false,
            rows: {
                Pos: Object.values(posData).map(row => {
                    row.AvgAge = row.Age / row.Players;
                    row.YSZiPS = row.YSNew / row.zipsWAR;
                    row.AAVZiPS = row.AAVNew / row.zipsWAR;
                    return row;
                }).filter(row => row.Age != null),
                SignDate: Object.values(signDateData).map(row => {
                    row.AvgAge = row.Age / row.Players;
                    row.YSZiPS = row.YSNew / row.zipsWAR;
                    row.AAVZiPS = row.AAVNew / row.zipsWAR;
                    return row;
                }).filter(row => row.Age != null),
                Total: Object.values(totalData).map(row => {
                    row.AvgAge = row.Age / row.Players;
                    row.YSZiPS = row.YSNew / row.zipsWAR;
                    row.AAVZiPS = row.AAVNew / row.zipsWAR;
                    return row;
                }).filter(row => row.Age != null),
            }
        });

        function adjustProperties(row) {
            let retRow = Object.assign({}, row);

            retRow.Category = row[resData.columnMap.player.Player];
            retRow.Players = 1;
            retRow.SigningDate = row[resData.columnMap.contract.SigningDate]
            retRow.PositionAtSigning = row[resData.columnMap.contract.PositionAtSigning]
            retRow.AvgAge = nullToZero(row[resData.columnMap.player.Age]);
            retRow.MLBContracts = row[resData.columnMap.contract.ContractLeague] === 'Major' ? 1 : 0;
            retRow.MiLBContracts = row[resData.columnMap.contract.ContractLeague] === 'Minor' ? 1 : 0;
            retRow.TermExp = nullToZero(row[resData.columnMap.player.Term]);
            retRow.YSExp = nullToZero(row[resData.columnMap.player.YS]);
            retRow.AAVExp = nullToZero(row[resData.columnMap.player.AAV]);
            retRow.TermNew = nullToZero(row[resData.columnMap.contract.Term]);
            retRow.YSNew = nullToZero(row[resData.columnMap.contract.YS]);
            retRow.AAVNew = nullToZero(row[resData.columnMap.contract.AAV]);
            retRow.GuaranteedNew = nullToZero(row[resData.columnMap.contract.TotalGuaranteed]);
            retRow.aWAR = nullToZero(row[resData.columnMap.player.aWAR]);
            retRow.zipsWAR = nullToZero(row[resData.columnMap.player['zipsWAR as Batter']])
                + nullToZero(row[resData.columnMap.player['zipsWAR as Pitcher']]);

            return retRow;
        }

        function dataAccumulator(acc, cur) {
            let retData = {};

            retData.Category = acc.Category;
            retData._children = acc._children;
            retData.Players = nullToZero(acc.Players) + 1;
            retData.Age = nullToZero(acc.Age) + nullToZero(cur[resData.columnMap.player.Age]);
            retData.MLBContracts = nullToZero(acc.MLBContracts) +
                (cur[resData.columnMap.contract.ContractLeague] === 'Major' ? 1 : 0);
            retData.MiLBContracts = nullToZero(acc.MiLBContracts) +
                (cur[resData.columnMap.contract.ContractLeague] === 'Minor' ? 1 : 0);
            retData.Unsigned = nullToZero(acc.Unsigned) +
                (cur[resData.columnMap.player.Age] == null ? 1 : 0);
            retData.TermExp = nullToZero(acc.TermExp) + nullToZero(cur[resData.columnMap.player.Term]);
            retData.YSExp = nullToZero(acc.YSExp) + nullToZero(cur[resData.columnMap.player.YS]);
            retData.AAVExp = nullToZero(acc.AAVExp) + nullToZero(cur[resData.columnMap.player.AAV]);
            retData.TermNew = nullToZero(acc.TermNew) + nullToZero(cur[resData.columnMap.contract.Term]);
            retData.YSNew = nullToZero(acc.YSNew) + nullToZero(cur[resData.columnMap.contract.YS]);
            retData.AAVNew = nullToZero(acc.AAVNew) + nullToZero(cur[resData.columnMap.contract.AAV]);
            retData.GuaranteedNew = nullToZero(acc.GuaranteedNew) + nullToZero(cur[resData.columnMap.contract.TotalGuaranteed]);
            retData.aWAR = nullToZero(acc.aWAR) + nullToZero(cur[resData.columnMap.player.aWAR]);
            retData.zipsWAR = nullToZero(acc.zipsWAR) + nullToZero(cur[resData.columnMap.player['zipsWAR as Batter']])
                + nullToZero(cur[resData.columnMap.player['zipsWAR as Pitcher']]);

            return retData;
        }

        function nullToZero(data) {
            if (data == null || isNaN(data))
                return 0;
            return Number(data);
        }
    }

    function updateNotes(formOptions) {
        let notes = {
            formNotes: [],
        };

        if (formOptions) {
            formOptions.filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    return (
        <div className='body xxb-report pdfSection'>
            <div className='pdfChunk'>
                <h2>XXB Report</h2>
                <RecentSigningsForm
                    filters={columnGroups?.filters}
                    submit={getData}
                    exporters={
                        data.rows &&
                        <React.Fragment>
                            <ExcelExporter
                                    columns={{
                                        Pos: data.columns,
                                        SignDate: data.columns,
                                        Total: data.columns,
                                    }}
                                    data={data.rows}
                                    notes={{
                                        Pos: excelNotes,
                                        SignDate: excelNotes,
                                        Total: excelNotes,
                                    }}
                                    reportName={'XXBReport'}
                                    sheetNames={['Pos', 'SignDate', 'Total' ]}
                            />
                            <PDFExporter fileName='XXBReport' />
                        </React.Fragment>
                    }
                />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data?.rows &&
                <>
                    <div
                        className='pdfChunk tableChunk'
                        style={{
                            width: (data.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                        }}
                    >
                        <TabulatorTable
                            cols={data.columns}
                            data={data.rows.Pos}
                            dataTreeElementColumn={'Category'}
                        />
                        <br />
                    </div>
                    <div
                        className='pdfChunk tableChunk'
                        style={{
                            width: (data.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                        }}
                    >
                        <TabulatorTable
                            cols={data.columns}
                            data={data.rows.SignDate}
                            dataTreeElementColumn={'Category'}
                        />
                        <br />
                    </div>
                    <div
                        className='pdfChunk tableChunk'
                        style={{
                            width: (data.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                        }}
                    >
                        <TabulatorTable
                            cols={data.columns}
                            data={data.rows.Total}
                            dataTreeElementColumn={'Category'}
                        />
                        <br />
                    </div>
                </>
            }
            {
                data?.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
        </div>
    );
}

export default XXBReport;


function RecentSigningsForm(props) {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        if (props.filters == null)
            return;

        let myFilters = [];

        props.filters
            .forEach((column, filterIndex) => {
                let filter = Object.assign({}, createFilter(filterIndex, column));
                if (filter.statId === 2 && filter.value == null)
                    filter.value = (new Date()).getFullYear() + 1;
                myFilters.push(filter);
            });

        setFilters(myFilters);
    }, [props.filters]);

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function submitForm() {
        let formOptions = {
            filters: filters,
        };

        props.submit(formOptions);
    }

    return (
        <React.Fragment>
            <h5>Advanced Filters</h5>
            <div className='advanced-filter'>
                <Row>
                    <Col xs={12} md={2} xl={2}
                        key={1}
                    >
                        <b>Season:</b>
                    </Col>
                    <Col xs={12} lg={5} xl={4}
                        key={2}
                    >
                        <Form.Control
                            as='select'
                            onChange={(e) => setFilter(0, 'value', e.target.value)}
                        >
                            {
                                Array.from(
                                    Array((new Date()).getFullYear() + 1 - 2018)
                                        .fill((new Date()).getFullYear() + 1),
                                    ((season, index) => `${season - index}`)
                                ).map((option, curIndex) =>
                                    <option
                                        selected={props.filter?.value === option ? 'selected' : false}
                                        key={curIndex}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                )
                            }
                        </Form.Control>
                    </Col>
                </Row>
                {
                    filters.filter(filter => filter.statId !== 2).map(filter =>
                        <StatFilter
                            filter={filter}
                            setFilter={setFilter}
                        />
                    )
                }
            </div>

            <br />
            <Row className='pdfIgnore'>
                <Button variant='success'
                    onClick={submitForm}
                >
                    Submit
                </Button>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    {props.exporters}
                </div>
            </Row>
        </React.Fragment>
    );
}