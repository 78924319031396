import React from 'react';
//import * as jsPDF from 'jspdf';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const splitByEleReports = [
    'Awards',
    'CompReport',
    'Contracts',
    'LargestContracts',
    'PlayerAwards',
    'RankReport',
    'RecentSigningsReport',
    'Transactions',
]
const hybridSplitRepoerts = [
    'ArbitrationReport',
    'ReliefPitcherReport',
    'SplitsReport',
    'TransactionsReport',
    'XXBReport'
]

function PDFExporter(props) {
    let scrollPos = !props.scrollPos ? { x: 0, y: 0 } : props.scrollPos;

    const exportPdf = () => {
        window.scrollTo(0, 0);
        let pdfSection = window.document.getElementsByClassName('pdfSection')[0];
        if (pdfSection.hasAttribute('hidden')) pdfSection.removeAttribute('hidden');
        let width = pdfSection.scrollWidth + 50;
        let height = pdfSection.scrollHeight + 50;
        changeStylingForPdf(pdfSection);
        

        // Split the html into pages at most 1100 pixels tall
        let chunks = []
        chunks.push(...Array.from(pdfSection.getElementsByClassName('pdfChunk')));
        
        let start = new Date();

        function htmlToCanvas() {
            html2canvas(pdfSection, { width: width, height: height, scale: 2 })
                .then((canvas) => {
                    let pages = [];
                    if (splitByEleReports.includes(props.fileName))
                        pages = getPagesSplitByEles(chunks);
                    else if (['ArbitrationReport', 'ReliefPitcherReport', 'SplitsReport', 'TransactionsReport', 'XXBReport'].includes(props.fileName))
                        pages = getPagesHybridSplit(chunks);
                    
                    let imgData = canvas.toDataURL('image/jpeg');
                    //let doc = new jsPDF('p', 'px', 'a4');
                    const doc = new jsPDF({
                        orientation: 'l',
                        unit: 'px',
                        format: 'a4',
                        hotfixes: ['px_scaling']
                    });
                    doc.deletePage(1);
                    let curStartHeight = 0;
                    let widthRatio = width / canvas.width;
                    let heightRatio = height / canvas.height;
                    for (let i = 0; i < pages.length; i++) {
                        if (!pages[i].ignore) {
                            doc.addPage([width, pages[i].height]); //900);
                            doc.addImage(
                                imgData,
                                'JPEG',
                                13,
                                curStartHeight * -1 + 12,
                                canvas.width * widthRatio,
                                canvas.height * heightRatio,
                                'test'
                            );
                        }
                        curStartHeight += pages[i].height;
                    }
                    doc.save(props.fileName + '.pdf');
                    console.log((new Date()) - start);
                })
                .then(() => {
                    if (props.fileName === 'Leaderboards') {
                        chunks[0].parentElement.setAttribute('hidden', '');
                    }
                    changeStylingForHtml(pdfSection);
                })
            window.scrollTo(scrollPos.x, scrollPos.y);
        }

        window.setTimeout(htmlToCanvas, 500);
        
        

        


        function changeStylingForPdf(pdfSection) {
            // Remove the blue background color from sorted headers when displaying on pdf.
            let sortedHeaderEles = Array.from(pdfSection.getElementsByClassName('tabulator-col tabulator-sortable'));
            sortedHeaderEles.forEach(e => {
                if (['descending', 'ascending'].includes(e.getAttribute('aria-sort'))) {
                    e.setAttribute('style', e.getAttribute('style') + ' background-color: #e6e6e6 !important;');
                    e.firstChild.firstChild.setAttribute(
                        'style',
                        e.firstChild.firstChild.getAttribute('style')
                            ? e.firstChild.firstChild.getAttribute('style')
                            : 'text-decoration-color: #e6e6e6;'
                    );
                }
            });

            // Hide chunks that should be ignored from pdf
            let pdfIgnoreChunks = Array.from(pdfSection.getElementsByClassName('pdfIgnore'));
            pdfIgnoreChunks.forEach(pdfIgnoreChunk => {
                pdfIgnoreChunk.style.opacity = '0';
            });
        }

        function changeStylingForHtml(pdfSection) {
            // Add the blue background back to the sorted headers after the pdf images have been created.
            let sortedHeaderEles = Array.from(pdfSection.getElementsByClassName('tabulator-col tabulator-sortable'));
            sortedHeaderEles.forEach(e => {
                if (['descending', 'ascending'].includes(e.getAttribute('aria-sort'))) {
                    e.setAttribute('style', e.getAttribute('style').substring(0, e.getAttribute('style').indexOf(' background')));
                    e.firstChild.firstChild.setAttribute('style', '');
                }
            })

            // Display chunks that were ignored from pdf
            let pdfIgnoreChunks = Array.from(pdfSection.getElementsByClassName('pdfIgnore'));
            pdfIgnoreChunks.forEach(pdfIgnoreChunk => {
                pdfIgnoreChunk.style.opacity = '1';
            });
        }
    }

    return(
        <img
            width='80'
            height='100'
            src={'./images/PDFButton.png'}
            alt=''
            type='button'
            onClick={(e) => {
                e.preventDefault();
                exportPdf();
            }}
        />
    )
}

// Currently this only works when table is last ele
function getPagesHybridSplit(eles) {
    let pages = [];

    pages = getEleGroups(eles).flatMap(eleGroup => {
        if (eleGroup.isTableChunk)
            return getPagesSplitByRow(eleGroup.eles[0]);
        else
            return getPagesSplitByEles(eleGroup.eles);
    })

    return pages;

    function getEleGroups(eles) {
        let eleGroups = [
            {
                eles: [],
                isTableChunk: eles[0].getAttribute('class').includes('tableChunk'),
            },
        ];

        eles.forEach(ele => {
            let isTableChunkEle = ele.getAttribute('class').includes('tableChunk');
            if (isTableChunkEle === eleGroups[eleGroups.length - 1].isTableChunk)
                eleGroups[eleGroups.length - 1].eles.push(ele);
            else {
                eleGroups.push({
                    eles: [ ele ],
                    isTableChunk: isTableChunkEle,
                })
            }
        })

        return eleGroups;
    }
}

function getPagesSplitByEles(eles, initHeightOverride) {
    let pages = [];
    let page = {
        height: initHeightOverride ?? getInitHeight(eles[0])
    };

    eles.forEach(ele => {
        let eleHeight = getEleHeight(ele);

        if (page.height + eleHeight < 1100) {
            page.height += eleHeight;
        }
        else {
            pages.push(page);
            page = {
                height: eleHeight,
                ignore: hideEleFromPdf(ele)
            }
        }
    });
    pages.push(page);
    
    return pages;

    function hideEleFromPdf(ele) {
        return ele.getAttribute('class').includes('pdfIgnore')
    }

    function getEleHeight(ele) {
        let offsetHeight = ele.offsetHeight;
        // add margin from tabulator class
        Array.prototype.forEach.call(ele.children, (eleChild) => {
            offsetHeight += eleChild.getAttribute('class') === 'tabulator'
                ? 25
                : 0;
        })

        return offsetHeight;
    }

    function getInitHeight(ele) {
        let initHeight = 0;
        // Add date range height if exists.
        initHeight += ele.parentElement.getElementsByClassName('dateRange').length > 0
            ? 23
            : 0;

        //initHeight += ele.parentElement.className.includes('pdfSection')
        //    ? 25
        //    : 0;

        return initHeight;
    }
}

function getPagesSplitByRow(table) {
    let initHeight = 36; //add margin from top and border
    let rowsHeadersFooters = Array.from(table.getElementsByClassName('tabulator-header'))
        .concat(Array.from(table.getElementsByClassName('tabulator-row')))
        .concat(Array.from(table.getElementsByClassName('tabulator-footer')));
    let pages = getPagesSplitByEles(rowsHeadersFooters, initHeight)
    pages[pages.length - 1].height = pages[pages.length - 1].height + 24; //add margin from top
    return pages;

//    let rows = table.getElementsByClassName('tabulator-row').length + Math.ceil(headerHeight / 23);
//    let pages = Array.from(new Array(Math.floor(rows / 46)),
//        item => {
//            return { height: 1058, ignore: false }
//        });
//    pages.push({
//        height: rows % 46 * 23 + 24, //add margin from top;,
//        ignore: false,
//    });

//    return pages;
}

export default PDFExporter;